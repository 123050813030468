.searchBar{
    display: flex;
    flex-direction: column;
    margin-top: 20px;
}
.search-input{
    width: 100vw;
    max-width: 800px;
    padding: 5px 10px;
    margin-bottom: 20px;
    font-size: 16px;
}