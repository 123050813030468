.promotion-modal__comment-form textarea {
  border-radius: 4px;
  border: 1px solid #aaaa;
  width: 100%;
  padding: 5px;
  margin-bottom: 5px;
}

.promotion-modal__comment-form {
  margin-top: 40px;
}
