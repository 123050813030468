.card-link{
    text-decoration: none;
    border: 1px solid #239bd2;
    border-radius: 5px;
    padding: 8px 10px;
    text-align: center;
}
.card-link:visited{
    color: #239bd2;
}

p::first-letter{
    text-transform: uppercase;
}

.card{
    width: 100vw;
    max-width: 800px;
    display: flex;
    padding: 15px;
    background-color: #fff;
    margin-bottom: 20px;
    box-shadow: -0.5px 0.5px 2px black;
    border-radius: 4px;
}
.card-img{
    width: 200px;
    height: 200px;
    object-fit: cover;
}

.card-txt{
    margin-left: 30px;
    width: 100%;
    max-width: 570px;
}

.card-txt h2{
    color: #595959;
    font-weight: 400;
    font-size: 28px;
    margin-bottom: 20px;
    max-width: 540px;
}

.card-txt__price,
.card-txt__comment{
    color: #8f8f8f;
}

.card-txt__price{
    font-size: 45px;
}
.card-txt__comment{
    font-size: 30px;
    white-space: nowrap; 
    overflow: hidden; 
    text-overflow: ellipsis; 
    margin-bottom: 20px;
    max-width: 520px;
}

.card-links{
    width: 100%;
    max-width: 540px;
    display: flex;
    align-items: center;
    color: #1f86b6;
    font-size: 14px;
}

.card-edit{
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.card-edit a{
    margin-bottom: 10px;
}
.card-edit__component{
    text-decoration: none;
    color: #1f86b6;
    border: 1px solid #239bd2;
    border-radius: 5px;
    padding: 8px 8px;
}

button{
    margin-right: auto;
    background-color: unset;
    border: unset;
    color: #1f86b6;
    font-size: 14px;
    cursor: pointer;
}