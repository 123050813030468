.form form{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.form input{
    display: block;
    width: 70vw;
    max-width: 1000px;
    padding: 6px 15px;
    font-size: 16px;
    margin-top: 10px;
}

.form label{
    display: block;
    margin-top: 10px;
    align-self: start;
}

.form button{
    padding: 10px 20px;
    background-color: #239bd2;
    border: none;
    border-radius: 9px;
    color: white;
    font-size: 20px;
    margin-top: 20px;
}

.form button:hover{
    cursor: pointer;
}


