html,body{
    font-family: 'Roboto', sans-serif;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #f5f5f5;
}
*{
    box-sizing: border-box;
    padding: 0;
    margin: 0;
}
