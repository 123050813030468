.promotion-modal-comments-tree {
  padding: 0 10px;
  list-style: none;
  font-size: 14px;
  padding-left: 0;
}

.promotion-modal-comments-tree__item {
  display: flex;
  margin-top: 15px;
}

.promotion-modal-comments-tree__item__avatar {
  width: 49px;
  height: 49px;
  object-fit: cover;
  margin-right: 15px;
  border-radius: 49px;
}

.promotion-modal-comments-tree__item__name {
  font-weight: 600;
}

.promotion-modal-comments-tree__item__info p {
  margin-top: 5px;
  margin-bottom: 0;
}

.promotion-modal-comments-tree__answer-button {
  background-color: transparent;
  border: 0;
  color: #3498db;
  margin-top: 5px;
  padding: 0;
}

.promotion-modal-comments-tree__comment-box {
  margin-top: 10px;
  display: flex;
  align-items: flex-end;
}

.promotion-modal-comments-tree__send-button {
  background-color: transparent;
  border-radius: 4px;
  border: 1px solid #3498db;
  color: #3498db;
  height: 28px;
  margin-left: 15px;
  padding: 5px;
}
